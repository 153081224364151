import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Bukavu II" />
    <h3 className='underline-title'>Bukavu II</h3>
    <br />
    <br />
    <div className="table-responsive">
     <p>
       Sous des bananiers sacro-saints<br />
       Aux versants des collines mamelonnées<br />
       Et dans de pittoresques vallons<br />
       Se dissimulent de frêles cases feuillées<br />
     </p>
     <p>
       Pour attendre la fin des temps<br />
       Dans la pureté divine des matins radieux<br />
       Et aux déclins du soleil pluvieux<br />
       Le lac s'étale au fond de verdoyantes vallées<br />
     </p>
     <p>
       Les toits d'Eternit aux teintes cendrées<br />
       Dessinent aux passagers aériens ravis<br />
       La géographie totale de trois zones<br />
       KADUTU, BAGIRA, IBANDA elles s'appellent<br />
     </p>
     <p>
       Perchées aux flancs et aux cimes des collines<br />
       Les zones se regardent par dessus le lac<br />
       Leur combinaison engendre un paysage de charme<br />
       D'où l'harmonie exclusive bukavienne<br />
       D'où la douleur fugace d'un climat d'exception<br />
       Climat composé d'averses généreuses<br />
       Génératrices des délices de la Nature<br />
       Nature à la Pureté Virginale<br />
     </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 10 juin 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default SecondPage
